import React from "react"
import { graphql, Link } from "gatsby"
import PortableText from "@sanity/block-content-to-react"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import SEO from "../components/seo"
import Layout from "../components/layout"
import imageUrlBuilder from "@sanity/image-url"
import { toPlainText } from "../lib/helpers"
import styled from "styled-components"
import { PageHeader } from "../components/PageHeader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import Img from "gatsby-image"
const StyledLink = styled(Link)`
  margin: 30px 0;
  color: rgb(207, 37, 51);
  float: ${({ float }) => float};
`
const urlFor = source =>
  imageUrlBuilder({
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_DATASET,
  }).image(source)
export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      title
      _rawBody
      mainImage {
        alt
        asset {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
`
const Wrapper = styled.div`
  padding: 60px 40px;
  font-size: 16px;
  max-width: 1000px;
  margin: 0 auto;
  h1 {
    font-size: 35px;
    font-weight: 500;
  }
`
const MainImage = styled(Img)`
  max-width: 1000px;
  margin: 0 auto;
  max-height: 400px;
  border-radius: 6px;
  margin-bottom: 30px;
  box-shadow: 0px 8px 19px -5px rgba(0, 0, 0, 0.5);
`
const StyledImg = styled.img`
  border-radius: 6px;

  & {
    margin-right: 15px;
  }
`

const serializers = {
  types: {
    image: props => (
      <a
        data-attribute="SRL"
        href={urlFor(props.node.asset)
          .width(1000)
          .quality(80)
          .url()}
      >
        <StyledImg
          src={urlFor(props.node.asset)
            .width(300)
            .quality(80)
            .url()}
        />
      </a>
    ),
  },
}
const BlogPostTemplate = ({ data, errors }) => {
  const post = data && data.post
  return (
    <Layout>
      <SEO
        title={post.title || "Untitled"}
        description={toPlainText(post._rawBody)}
        image={post.mainImage}
      />
      <PageHeader>Aktualności</PageHeader>
      <Wrapper>
        <MainImage
          fluid={post.mainImage.asset.fluid}
          alt={post.mainImage.alt}
        />
        <h1>{post.title}</h1>

        {errors && <SEO title="GraphQL Error" />}
        <SimpleReactLightbox>
          <SRLWrapper>
            <PortableText
              serializers={serializers}
              blocks={post._rawBody}
              projectId={process.env.GATSBY_SANITY_PROJECT_ID}
              dataset={process.env.GATSBY_SANITY_DATASET}
            />
          </SRLWrapper>
        </SimpleReactLightbox>
        <StyledLink to="/aktualnosci/" float="left">
          <FontAwesomeIcon icon={faArrowLeft} />
          Wróć do listy postów
        </StyledLink>
      </Wrapper>
    </Layout>
  )
}

export default BlogPostTemplate
